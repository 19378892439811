import { Avatar, Badge } from 'antd';
import React, { useEffect, useState } from 'react'; 
import { Col, Container, Row } from 'react-bootstrap';
import TdtIcon from './TdtIcon';
import { FaCheck } from "react-icons/fa6";
import TdtImage from './TdtImage';
import TdtListStyle from './TdtListStyle';
import { WebApi } from 'shared/WebApi';

const projectpath = process.env.REACT_APP_API_URL; 


interface Reasonstworkprops{
    icon?:any 
    count?:number
}

const TdtWhoWeAre: React.FC<Reasonstworkprops> = (props) => {
  

  
  const [getWhoWeAreAll, setGetWhoWeAreAll] = useState<string>('');
  const [getWhoWeAreImageAll, setGetWhoWeAreImageAll] = useState<string>('');
  const getWhoweare = async () => {   
    try {
      const response = await WebApi('get', 'api/get_getWhoweare');
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data  
        setGetWhoWeAreAll(modifiedData[0].description);
        setGetWhoWeAreImageAll(modifiedData[0].image);
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
    getWhoweare()
  }, []) 


  
  const listdata =[
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
     ]

  return (
    <Container fluid className='TdtWhoWeAre'>
        <Container>
            <Row>
                <Col sm={12} md={6} className='whovr-col1-home d-flex align-items-center'> 
                      <div className='ckeditorpkgdtl'
                      dangerouslySetInnerHTML={{
                        __html: getWhoWeAreAll,
                      }}
                    /> 
                </Col>
                <Col sm={12} md={6} className='whovr-col2 shadow p-3  rounded'>
                    <TdtImage preview={false} classNameimg='' 
                    
                    src={projectpath + '/public/panelassets/images/uploads/' + getWhoWeAreImageAll} />   
                </Col>
            </Row>
        </Container>
    </Container>
  );
}

export default TdtWhoWeAre;