import React, { useEffect } from 'react';
import { Button, Checkbox, Form, type FormProps, Input, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {WebApi}  from '../shared/WebApi'

type FieldType = {
  name?: string;
  password?: string;
  remember?: string;
};

const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
  console.log('Success:', values);
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

interface LoginProps {
    // children: any, 
}

const Register: React.FC<LoginProps> = (props) => {


    
    const apiObject: any = {
        'user_id': localStorage.getItem('user_id')
    }
  
    
    useEffect(() => {
      });

      
      const navigate = useNavigate();

    const GetUsers = async (values: any) => { 
        
        values.token = "QUSIm9KOxnk6iW5ThEz69dyzWqSlQT1tQOxRQvhK";
        localStorage.setItem('token', values.token)

        try { 
            const response = await WebApi('post', 'api/register' , values, values.token);
            const responseData: any = response;
            if (responseData.status === 302) {
                const modifiedData = responseData.data;  
                console.log(modifiedData)
                navigate('/login')
            }
        } catch (error:any) {
            console.error('Error:', error);  
        }
    };

  return (
    <>
    <Row className='justify-content-center mx-auto py-5'>
        <Col sm={4}>
        <Form
            name="basic"  
            initialValues={{ remember: true }}
            onFinish={GetUsers}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
            label="User Name: Test User"
            name="name"
            rules={[{ required: true, message: 'Please input your name!' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Email: test@example.com"
            name="email"
            rules={[{ required: true, message: 'Please input your name!' }]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Password: password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            >
            <Input.Password />
            </Form.Item>

            {/* <Form.Item
            name="remember"
            valuePropName="checked" 
            >
            <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

            <Form.Item>
            <Button type="primary" htmlType="submit">
                Register
            </Button>
            </Form.Item>
        </Form>
        </Col>
    </Row>
            
    </>
  );
}

export default Register;
