import ReasonsToWork from 'components/websitecomponents/ReasonsToWork';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import TdtImage from 'components/websitecomponents/TdtImage';
import React, { useState } from 'react'; 
import { Col, Container, Row } from 'react-bootstrap';  
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaPencil, FaPhone, FaSquarePhone, FaWhatsapp, FaYoutube } from 'react-icons/fa6';
import { HiOutlineChatAlt } from 'react-icons/hi';
import { DownOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { Badge, Dropdown, Form, Space, Table } from 'antd';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtInput from 'components/websitecomponents/TdtInput';
import { WebApi } from 'shared/WebApi';
import TdtTextarea from 'components/websitecomponents/TdtTextarea';

interface DataType {
  key: React.Key;
  id: string;
  tourimage: string;
  tourname: string;
  startdate: string;
  totaladult: string;
  totalchild: string;
  transfer: string;
  tourtotalprice: string;
}

interface ExpandedDataType {
  key: React.Key;
  number: string;
  addontitle: string;
  noofadults: string;
  noofchildrens:string;
}

const items = [
  { key: '1', label: 'Action 1' },
  { key: '2', label: 'Action 2' },
]; 
  
interface Checkoutprops {

}
 
const expandedRowRender = () => {
  const columns: TableColumnsType<ExpandedDataType> = [
    { title: '#', dataIndex: 'number', key: 'number' },
    { title: 'Addon Title', dataIndex: 'addontitle', key: 'addontitle' },
    { title: 'No of Adults', dataIndex: 'noofadults',key: 'noofadults' },
    { title: 'No of Childresn', dataIndex: 'noofchildrens',key: 'noofchildrens' }, 
    { title: 'Addon Price',  dataIndex: 'addonprice', key: 'addonprice', 
    },
  ];

  const data = [];
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i.toString(),
      number:`${i}`,
      addontitle: '2 seat Dune Buggy for 30 minutes',
      noofadults: '2',
      noofchildrens: '0', 
      addonprice: 'AED 500', 
    });
  }
  return <Table columns={columns} dataSource={data} pagination={false} footer={() =>  
        <div className='d-flex justify-content-between tblfooterdetail gap-4'>
          <div className='tourtotal'>Tour Base Price :  <span><b>AED 12212</b></span></div>
          <div className='addontourtotal'>Addons Total Price :  <span><b>AED 32343</b></span></div>
          <div className='grandtourtotal'>Tour Total Price :  <span><b>AED 675676</b></span></div>
        </div> 
      } />;
};

const columns: TableColumnsType<DataType> = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Image', dataIndex: 'tourimage', key: 'tourimage' },
  { title: 'Tour Name', dataIndex: 'tourname', key: 'tourname' },
  { title: 'Tour Date', dataIndex: 'startdate', key: 'startdate' },
  { title: 'Adult', dataIndex: 'totaladult', key: 'totaladult' },
  { title: 'Child', dataIndex: 'totalchild', key: 'totalchild' },
  { title: 'Transfer', dataIndex: 'transfer', key: 'transfer' },
  { title: 'Tour Total Price', dataIndex: 'tourtotalprice', key: 'tourtotalprice' },
  { title: 'Action', key: 'operation', render: () =>  <TdtIcon icon={<HiOutlineChatAlt />}/>},
];

const data: DataType[] = [];
for (let i = 0; i < 3; ++i) {
  data.push({
    key: i.toString(),
    id: `${i}`,
    tourimage: 'Image',
    tourname: 'Private Tour Dubai',
    startdate: '2014-12-24',
    totaladult: '4 adults',
    totalchild: '2 childs',
    transfer: 'sharing',
    tourtotalprice: '222 AED',
  });
}


export const TdtCheckOut: React.FunctionComponent<Checkoutprops> = (props) => {
   
const [form] = Form.useForm()
const initialValues = { location_type : "Head Office"}
const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};
const onFinish = (values: any) => {
  
  // values.user_id = localStorage.getItem("user_id");
  // values.company_account_type = 1;
  WebApi('post','/company/add', values)
  .then(response => {
      const responseData:any = response;
      if (responseData.status == 200 && responseData.data.message == "success") { 
          form.resetFields();
      }else if(responseData.status == 200 && responseData.data.message == "error"){
          console.error('Error:'); 
      }
  })
  .catch(error => {
      console.error('Error:', error);
  });
};

   
    const reasonsToWorks = {
        "headerContent": {
          "mainText": "Reasons to",
          "spanText": "Book With Us"
        },
        "iconData": [
          {
            "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
            "label": "BEST PRICE GUARANTEE", 
          },
          {
            "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
            "label": "24X7 LIVE CHAT SUPPORT", 
          },
          {
            "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
            "label": "FAST BOOKING", 
          },
          {
            "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
            "label": "5 STAR FACILITIES", 
          },
          {
            "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
            "label": "WIFI COMING SOON", 
          },
         
        ]
      }
    return ( 
        <React.Fragment> 
 

          <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/banner-img-1.png)'}}> 
            <Container className='bannerbredcontent py-3'>
              <Row>
                <Col sm={12} className='py-5'>
                <h4 className='text-light'><a href='http://thedeluxetravel.com/'  className='text-white'> Home </a> / CheckOut</h4> 
                </Col>
              </Row>
            </Container>
            <div className='ovelaybred'></div>
          </Container> 

          <Container fluid className='position-relative py-5 chkoutpage'> 
            <Container>
              <Row>
                <Col sm={12} md={9} className='listcrdcontent gap-5'>  
                  <h4 className='pb-3'>Tours Information</h4>
                  <Table
                    columns={columns}
                    expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                    dataSource={data}
                    pagination={false} 
                    footer={() => 
                      <div className='d-flex justify-content-between tblfooterdetail'>
                          <div>
                            <TdtButton href={'check-out'} label={'Check Out'}/>
                          </div>
                          <div className='d-flex gap-4'>
                            <div className='tourtotal'>Grand Price : <span><b> AED 2,003.00</b></span></div>
                            <div className='addontourtotal'> <span><b>VAT : AED 100.15</b></span></div>
                            <div className='grandtourtotal'>  <span><b>AED 2,103.15</b></span></div>
                          </div>
                      </div> }
                  />
                  <h4 className='py-3'>Personal Information</h4>
                  <Form
                      name="AuditeeAddForm"
                      form={form}
                      initialValues={initialValues}
                      // initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="vertical"
                      // validateMessages={validateMessages}
                  > 
                      <Row>
                          <Col sm={12} md={6} className=' '> 
                              <div className='contcdiv1'>
                                  <TdtInput 
                                      className="inputclas class-fname"
                                      name="fname"
                                      type="text"
                                      required={true}
                                      requiredMessage="Please input your full name!"
                                      label='Full Name'
                                      placeholder='Enter full Name'
                                      allowClear={true}
                                      // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} md={6} className=' '> 
                              <div className='contcdiv1'>
                                  <TdtInput
                                      className="inputclas class-email"
                                      name="subject"
                                      type="text"
                                      required={true}
                                      requiredMessage="Please input your Email!"
                                      label='From Email'
                                      placeholder='Enter Email'
                                      allowClear={true}
                                      prefix={<TdtIcon icon={<FaEnvelope/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} md={4} className='  '> 
                              <div className='contcdiv2'>
                                  <TdtInput 
                                      className="inputclas class-phone"
                                      name="number"
                                      type="text"
                                      required={true}
                                      requiredMessage="Please input your number!"
                                      label='Phone Number'
                                      placeholder='Enter number'
                                      allowClear={true}
                                      prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} md={4} className='  '> 
                              <div className='contcdiv2'>
                                  <TdtInput 
                                      className="inputclas class-phone"
                                      name="number"
                                      type="text"
                                      required={true}
                                      requiredMessage="Please input your whatsapp number!"
                                      label='WhatsApp Number'
                                      placeholder='WhatsApp number'
                                      allowClear={true}
                                      prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>  
                          <Col sm={4} className='  '> 
                              <div className='contcdiv1'>
                                  <TdtInput
                                      className="inputclas class-subject"
                                      name="subject"
                                      type="text"
                                      required={false}
                                      requiredMessage="Please input your subject!"
                                      label='Pick Up Point'
                                      placeholder='Pick Up Point (Must Type Area Name)'
                                      allowClear={true}
                                      // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} md={6} className='  '> 
                              <div className='contcdiv1'>
                                  <TdtInput
                                      className="inputclas class-subject"
                                      name="subject"
                                      type="text"
                                      required={false}
                                      requiredMessage="Please input your subject!"
                                      label='Hotel Booking Name'
                                      placeholder='Hotel Booking Name'
                                      allowClear={true}
                                      // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} md={6} className='  '> 
                              <div className='contcdiv1'>
                                  <TdtInput
                                      className="inputclas class-subject"
                                      name="subject"
                                      type="text"
                                      required={false}
                                      requiredMessage="Please input your subject!"
                                      label='Room / Flat Number'
                                      placeholder='Room / Flat Number'
                                      allowClear={true}
                                      // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} className=' '> 
                              <div className='contcdiv1'>
                                  <TdtTextarea
                                      className="inputclas class-message"
                                      name="message"
                                      type="text"
                                      required={false}
                                      requiredMessage="Please input your Message!"
                                      label='Message'
                                      placeholder='Enter Message'
                                      allowClear={true}
                                      // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                      size="large"
                                      ruleType='text'
                                  /> 
                              </div>
                          </Col>
                          <Col sm={12} className=' d-flex'> 
                          <b>Read Our Cancellation Policy <a href=''>Click Here</a></b>
                              <TdtButton  htmlType={"book Now"} label='Submit' classNamebtn={'w-75'} />
                          </Col>
                      </Row>
                    </Form>
                  
                </Col>
                <Col sm={12} md={3} className='drtsfrsidbar'>
                  <div className='sidebartrours'>
                    <ReasonsToWork  reasonsToWorks={reasonsToWorks} className='detaipageresons mt-1 ' /> 
                    <div className='contactsinglecard'>
                      <div className='contentcontact'>
                        <h6 className='pb-3'>Need Help?</h6> 
                        <ul className='liststyle '><li><a href='https://web.whatsapp.com/send?phone=+923475278586'><TdtIcon icon={<FaSquarePhone/>} label={'+92347 5278586'}/></a></li><li> <a href='https://web.whatsapp.com/send?phone=+923475278586'><TdtIcon icon={<FaWhatsapp/>} label={'+92347 5278586'}/></a> </li><li> <a href='mailto:rajanaseem568@gmail.com'><TdtIcon icon={<FaEnvelope/>} label={'rajanaseem568@gmail.com'}/></a> </li></ul>
                        <p className='pt-3'>Available 24/7</p> 
                      </div> 
                      <div className='headercontact'>
                        <TdtImage src={process.env.PUBLIC_URL  + 'assets/deluxetravellogo.svg'} classNameimg='w-75' preview={false} />
                      </div> 
                      <div className='contactsnglfootr'>
                        <ul className=' d-flex liststyle '><li><TdtIcon icon={<FaLinkedin/>}/></li><li> <TdtIcon icon={<FaFacebook/>}/></li><li> <TdtIcon icon={<FaInstagram/>}/></li><li><TdtIcon icon={<FaYoutube/>}/></li></ul>
                      </div>
                    </div> 
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </React.Fragment>
    )
}

export default TdtCheckOut;





