import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import headerlogo from "../../assets/images/deluxetravellogo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import { WebApi } from 'shared/WebApi'; 
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

interface Homeprops {
className?: string
}

const { Header } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
): MenuItem {
    return {
        key,
        icon,
        label,
    } as MenuItem;
}

const itemsMenuAdmin: MenuItem[] = [
    
    getItem(<NavLink to="https://thedeluxetravel.com/tours/category/91"> Desert Safari  </NavLink>, ' Desert Safari '),
    getItem(<NavLink to="https://thedeluxetravel.com/tours/category/109"> Sight Seeing </NavLink>, ' Sight Seeing  '),
    getItem(<NavLink to="https://thedeluxetravel.com/tours/category/110"> Private Tours </NavLink>, ' Private Tours '),
    getItem(<NavLink to="https://thedeluxetravel.com/tours/category/114"> Executive </NavLink>, ' Executive'),  
    getItem(<NavLink to="https://thedeluxetravel.com/tours/category/111"> Desert Resorts </NavLink>, ' Desert Resorts'),  
    getItem(<NavLink to="https://thedeluxetravel.com/tours/category/113"> Dhow Cruise </NavLink>, ' Dhow Cruise '),  
    {
    key: 'sub1',
    label: 'Others', 
    className:'othermenu',
    children: [ 
        { key: '108', label: <NavLink  to="https://thedeluxetravel.com/tours/category/108">Theme Park</NavLink> },
        { key: '109', label: <NavLink to="https://thedeluxetravel.com/tours/category/112">Sky Tours</NavLink> },
        { key: '110', label: <NavLink to="https://thedeluxetravel.com/tours/category/115">Sea Advantures</NavLink> },
        { key: '111', label: <NavLink to="https://thedeluxetravel.com/tours/category/117">Quad Biking</NavLink> }, 
    ],
    }, 
     
];


export const HeaderNavbar: React.FunctionComponent<Homeprops> = (props) => {
    
    return (
        <Header className={`setheaderweb ${props.className}`}>
            <Container fluid className='p-0'>
            <Container>
                    <Row> 
                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={itemsMenuAdmin} style={{ flex: 1, minWidth: 0 }} className='headertours' />
                    </Row>  
                </Container >
            </Container >

        </Header>
    )
}

export default HeaderNavbar; 