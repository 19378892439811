import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { motion, useScroll, useSpring } from "framer-motion";
import OurServices from './homesections/OurServices';
import BannerSecTwo from './homesections/BannerSecTwo';
import TdtImage from 'components/websitecomponents/TdtImage';



interface Aboutusprops {

}
export const PrivacyPolicy: React.FunctionComponent<Aboutusprops> = (props) => {
   



    return (
        <React.Fragment>  
           
            <Container fluid className='pb-5'> 
                <Container className='py-5'>
                    <Row className='privacypoliscy'>  
                        <Col sm={12}   className='  px-sm-1 px-md-5 py-3'> 
                        <h4>DELUXE TRAVELS PRIVACY POLICY</h4>
<p>This Privacy Policy explains how we collect, use and protect information when you use our website.
We are committed to ensuring your privacy is protected and will therefore use any and all information collected only in accordance with this policy.</p>

<h4>Device Information we collect</h4>
<p>Some information are collected automatically when you visit our website. These include but are not limited to:</p>
1.Information about your web browser,
2.Information about your device model,
3.IP Address,
4.Time Zone,
5.Cookies installed on your device,
6.Individual web pages or products you view,
7.Which web pages or search terms directed you to this website,
8.Information on how you interact with the site.

<h4>Personal Data we collect</h4>
<p>While exploring our website and everything we have to offer, we may ask you to provide us with certain personal information that can be used to identify you. These include but are not limited to:</p>
!1.First and last name,
2.E-mail address,
3.Phone Number,
4.Your communication preferences,
5.Payment card details to secure bookings.

<h4>How we collect information</h4>
<p>We collect information using the following:</p>
1. Booking and enquiry forms
<p>When you book an experience with Deluxe Travel or want a member of our team to reach out to you, you will be asked to fill out a form. This form might require information such as your first name, last name, number and e-mail address</p>
<h4>Payment details</h4>
<p>In order to secure your booking after you filled out the form, we will need to collect your card details to authorise an immediate payment for the total of your booking.</p>
<h4>Forms when downloading brochures and e-book</h4>
<p>If you want to download our experiences brochure or an educational e-book, you will be asked to fill out a form. This form might require information such as your first name, last name, number and e-mail address.</p>
<h4>Cookies</h4>
<p>A small file that asks permission to be placed on your device’s hard drive. This helps analyse web traffic and for the site to respond to you as an individual.</p>
<h4>Log files</h4>
<p>Track actions that collect data including your IP Address, browser type, Internet service provider, referral pages, exit pages and date and time stamps.</p>
<h4>Tags or pixels</h4>
<p>Electronic files used to record information about how you browse our website.</p>
<h4>How we use your personal information</h4>
<p>We use the information we collect to understand your needs and provide you with a better experience. We can also use the information for the following reasons:</p>
1.Internal record keeping,
2.To improve our products and services,
3.To communicate with you, whether it be promotional material, details about your booking or notices,
4.To screen for potential risks and fraud in order to protect our website.
<h4>Disclosure and sharing of information we collect</h4>
<p>Your safety and security is of utmost important to us. Therefore, we take disclosing and sharing of your information very seriously:</p>
1.We only share your information with third parties to help us use your information as described above.
2.We will never share or sell your data to any third party for their own marketing purposes.
3.We use Google Analytic to help us understand how you navigate the website. You can read more about their privacy policy here.
4.We may share your information to be compliant with applicable laws and regulations or if required by law or to otherwise protect our rights.
5.In order to prevent unauthorized access to your information, we put in place physical, electronic and managerial procedures to safeguard the information we collect online.
6.Any payment or card details will not be stored.
7.Any other personally identifiable information will not be shared, rented or leased to any third parties.
8.Our website has a SSL Certificate (Seure Socket Layer) which activates the padlock symbol in your search bar to ensure all connections and content between servers are secure.
<h4>Links to other websites</h4>
<p>Our website may contain links to external websites of interest. Once you have leaved our site via these links, please note that we don’t have any control over other websites. We cannot be held responsible for any information they collect from you while visiting their site.</p>

<h4>Your rights</h4>
<p>As your comfort and ease of mind are very important to us, please know that you have the following rights:</p>
1.At any time, even if you have previously consented, you have the right to ask us not to use your personal information and/or not to disclose it to any third parties for marketing purposes. You can do this by sending an e-mail to holidays@thedeluxetravel.com
2.After you completed your experience with us, you can ask us to delete your information from our system.
If you opted to download our brochure or e-books, you will also receive e-mails with e-books with useful information in the short period after. You can opt to unsubscribe from these at any time and your information won’t be stored anywhere.
<h4>Changes</h4>
<p>We may update the privacy policy occasionally to stay up to date with the latest requirements or due to changes in our practices.</p>

<h4>Acceptance of this privacy policy</h4>
<p>By using our website, you accept the terms, conditions and information set forth in this privacy policy. If you do not agree to the privacy policy.</p>

<h4>Complaints or concerns</h4>
<p>If you have any concerns or wish to raise a complaint on how we collected or used your information, please inform us as soon as possible so we can address it immediately. You can do so by sending an e-mail to holidays@thedeluxetravel.com or calling +971 52 9535 800</p>
 
                        </Col>  
                    </Row> 
                </Container> 
            </Container> 

        </React.Fragment> 


    )
}

export default PrivacyPolicy;




