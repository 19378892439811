import TdtButton from 'components/websitecomponents/TdtButton';
import React, { useEffect, useState } from 'react';   
import { Col, Container, Row } from 'react-bootstrap';
import DrwrAddBanner from './DrwrAddBanner';
import DrwrAddReasons from './DrwrAddReasons';
import DrwrMostPopular from './DrwrMostPopular';
import DrwrWhoWeAre from './DrwrWhoWeAre';
import FaqsHomepgDrwr from './FaqsHomepgDrwr';
import DrwrAddMoreInfo from './DrwrAddMoreInfo';

interface Dashboardprops { 
}

   

export const Dashboard: React.FunctionComponent<Dashboardprops> = (props) => { 

    const [viewBannerDrwr, setViewBannerDrwr] = useState(false); 
    const addBannerFun = () => {
        setViewBannerDrwr(true);
    }  
    const [viewReasonsDrwr, setViewReasonsDrwr] = useState(false); 
    const addReasonFun = () => {
        setViewReasonsDrwr(true);
    }   
    const [viewMostPopular, setViewMostPopular] = useState(false); 
    const addMostPopularFun = () => {
        setViewMostPopular(true);
    }   
    const [viewWhoWeAreFun, setViewWhoWeAreFun] = useState(false); 
    const addWhoWeAreFun = () => {
        setViewWhoWeAreFun(true);
    }   

    const [addFaqs, setaddFaqsFun] = useState(false); 
    const addFaqsFun = () => {
        setaddFaqsFun(true);
    }   
    const [addMoreInfo, setAddMoreInfo] = useState(false); 
    const addMoreInfoFun = () => {
        setAddMoreInfo(true);
    }   
    
    
    const onClose = () => {
        setViewBannerDrwr(false);
        setViewReasonsDrwr(false)
        setViewMostPopular(false);
        setViewWhoWeAreFun(false);
        setaddFaqsFun(false);
        setAddMoreInfo(false);
    };

   
    return (  
        <React.Fragment> 
            <DrwrAddBanner open={viewBannerDrwr} onClose={onClose} title='Add Banner' placement='right'/>
            <DrwrAddReasons open={viewReasonsDrwr} onClose={onClose} title='Add Reasons' placement='right'/>
            <DrwrMostPopular open={viewMostPopular} onClose={onClose} title='Most Popular' placement='right'/>
            
           {viewWhoWeAreFun &&  <DrwrWhoWeAre open={viewWhoWeAreFun} onClose={onClose} title='Who We Are, Book Tour With Us CMS' placement='right'/> }
           
           {addFaqs && <FaqsHomepgDrwr open={addFaqs} onClose={onClose} title='Faqs Home Page' placement='right'/>}
            {addMoreInfo && <DrwrAddMoreInfo open={addMoreInfo} onClose={onClose} title='More Information' placement='right'/> }
           

           <Container fluid className='dashboardpage'> 
                <Container  className='px-0 py-4'>
                    <Row>
                        <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>Banner Home</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addBannerFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addBannerFun} label={'View'}/>
                                </div> 
                                {/* <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/> */}
                            </div>
                            </div> 
                        </Col>
                        <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>Reasons Book</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addReasonFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addReasonFun} label={'View'}/>
                                </div> 
                                {/* <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/> */}
                            </div>
                            </div> 
                        </Col>
                        {/* <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>Most Popular</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addMostPopularFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addMostPopularFun} label={'View'}/>
                                </div> 
                                <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/>
                            </div>
                            </div> 
                        </Col> */}
                        <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>Who we are</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addWhoWeAreFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addWhoWeAreFun} label={'View'}/>
                                </div> 
                                {/* <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/> */}
                            </div>
                            </div> 
                        </Col>
                        <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>FAQs</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addFaqsFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addFaqsFun} label={'View'}/>
                                </div> 
                                {/* <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/> */}
                            </div>
                            </div> 
                        </Col>
                        <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>More Information</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addMoreInfoFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addMoreInfoFun} label={'View'}/>
                                </div> 
                                {/* <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/> */}
                            </div>
                            </div> 
                        </Col>
                    </Row>
                </Container>
            </Container> 
        </React.Fragment>
    )
}

export default Dashboard;




