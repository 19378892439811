import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { motion, useScroll, useSpring } from "framer-motion";
import OurServices from './homesections/OurServices';
import BannerSecTwo from './homesections/BannerSecTwo';
import TdtImage from 'components/websitecomponents/TdtImage';



interface Galleryprops {

}
export const Gallery: React.FunctionComponent<Galleryprops> = (props) => {
   



    return (
        <React.Fragment> 
            <Helmet>
                <title>Gallery</title>
                <meta name="description" content="Gallery are available for you" />
                <link rel="canonical" href="/about" />
                <meta property="og:title" content="A very important title" />
            </Helmet>   

            <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/burjkahlifa-dubai.jpg)'}}> 
                <Container className='bannerbredcontent'>
                    <Row>
                        <Col sm={12} className='py-5'>
                        <h4 className='text-light'>Gallery</h4>
                        </Col>
                    </Row>
                </Container>
                <div className='ovelaybred'></div>
            </Container> 


            <Container fluid className=''> 
                <Container className='py-5'>
                    <Row> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='pb-3'>Look into Our Gallery</h1>
                            <p className='px-sm-1 px-md-5'>The  Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America. Book your travel  needs, from the hotels to <br/> the cheapest flights on Price.The  Deluxe Travel continues their tour in 2024, touching  down in Subcontinent,  Europe and  America. <br/>Book your travel  needs, from the hotels to the cheapest flights on Price.</p>
                        </Col>
                    </Row>
                    <Row className='pb-5'> 
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                        <Col sm={12} md={3} className='text-center'>
                            <div className='gallerimges  my-2'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'} /> 
                            </div>
                         </Col>  
                    </Row>  
                </Container > 
            </Container> 

        </React.Fragment> 


    )
}

export default Gallery;




