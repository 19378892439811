import React from 'react'; 
import { NavLink } from 'react-router-dom';
import { Image, List, Typography } from 'antd';

 


interface Tdtlistprops {
  listdata?:any
  header?:any
  footer?:any
  className?:string
  bordered?:boolean
}

const TdtListStyle: React.FC<Tdtlistprops> = (props) => {
  return (
    <React.Fragment> 
        <List
        header={props.header}
        footer={props.footer}
        bordered={false}
        className={props.className}
        dataSource={props.listdata}
        renderItem={(item:any) => (
        <List.Item>
          {item}
        </List.Item>
      )}
    />



    
    </React.Fragment>
  );
}

export default TdtListStyle;