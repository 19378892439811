import TdtButton from 'components/websitecomponents/TdtButton';
import TdtIcon from 'components/websitecomponents/TdtIcon'; 
import React, { useEffect, useState } from 'react';

import { Avatar, List} from "antd"; 
import { Col, Container, Row } from 'react-bootstrap';   
import type { TableColumnsType, TableProps } from 'antd';  
import { FaBarsProgress, FaCalendar, FaCar, FaClock, FaEnvelope, FaFacebook, FaHourglass, FaInstagram, FaLanguage, FaLinkedin, FaMoneyBill, FaRegStarHalfStroke, FaSquarePhone, FaStar, FaWhatsapp, FaWifi, FaYoutube } from 'react-icons/fa6';
import TdtTable from 'components/websitecomponents/TdtTable';
import { HiOutlineChatAlt } from 'react-icons/hi';
import ReasonsToWork from 'components/websitecomponents/ReasonsToWork';
import TdtListStyle from 'components/websitecomponents/TdtListStyle';
import TdtTimeline from 'components/websitecomponents/TdtTimeline';
import TdtCauroselPackage from 'components/websitecomponents/TdtCauroselPackage';
import TdtImage from 'components/websitecomponents/TdtImage'; 
import TdtBookDrwer from './TdtBookDrwer';
import { useParams } from 'react-router-dom';
import { WebApi } from 'shared/WebApi';  
import SliderDetailTourPage from 'components/websitecomponents/SliderDetailTourPage';
const projectpath = process.env.REACT_APP_API_URL; 

  interface DesertSafariprops {}
  interface DataType {
    key: React.Key;
    tourserviceone: any;
    tourservicetwo: any; 
  } 
  interface AdditionalOption {
    title: string;
    price: number;
    persons: number;
  } 
  interface PackageDataType {
    pid: number;
    cat_id: number;
    cat_title: string;
    title: string;
    description: string;
    price: number;
    discount_price: number;
    child_price: number;
    child_discount_price: number;
    duration_type: string; 
    pickup_time: string;
    duration: string;
    rating: number;
    package_image: string;
    package_image_thumbnail: string;
    transfer_type: string;
    transfer_type_adultprice: number;
    transfer_type_childprice: number;
    tags: string;
    image: string;
    opt_title: string;
    opt_price: number;
    opt_persons: number;
    long_description:string;
    SlideImages: string[];  
    AdditionalOptions: { [key: string]: AdditionalOption }; 
} 

export const TourDetail: React.FunctionComponent<DesertSafariprops> = (props) => {
    
  const { id } = useParams();    
  const [packageData, setPackageData] = useState<PackageDataType | null>(null); 
  
  const [slideImages, setSlideImages] = useState<File[]>([]);

  const getPackageById = async () => {   
    try {
      const response = await WebApi('get', `api/get_package_byId/${id}`); 
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData  = responseData.data.data; // Assuming this has the correct structure
        setPackageData(modifiedData);  
       
        setSlideImages(modifiedData.SlideImages); 
        
        // const modifiedDataAdd: PackageDataDtype = responseData.data.data.AdditionalOptions[0].persons
        // setPackageAddtionalOptData(modifiedDataAdd)
        // const modifiedDataImges: PackageDataDtype = responseData.data.data.SlideImages
        // setPackageDataSlides(modifiedDataImges)
        debugger
    } 
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };

     
  const [relatedPackageData, setRelatedPackageData] = useState<any[]>([]); 
  const getRelatedPackageById = async () => {   
    try {
      const response = await WebApi('get', `api/get_related_packages/${id}`); 
      
      
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData  = responseData.data.data; // Assuming this has the correct structure
        setRelatedPackageData(modifiedData); 
       
    } 
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };
  
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const getCategories = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_categories');
      const responseData: any = response; 
      if (responseData.data.status === true) { 
          const modifiedData = responseData.data.data  
          setAllCategories(modifiedData);  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };
  


useEffect(() => {
  if (id) {
    getPackageById();
    getRelatedPackageById();
    getCategories();
  }
}, [id])


// useEffect(() => {  
// }, []) 
  
  const reasonsToWorks = {
    "headerContent": {
      "mainText": "Reasons to",
      "spanText": "Book With Us"
    },
    "iconData": [
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "BEST PRICE GUARANTEE", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "24X7 LIVE CHAT SUPPORT", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "FAST BOOKING", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "5 STAR FACILITIES", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "WIFI COMING SOON", 
      },
     
    ]
  }

  const tblheadcolumns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'tourserviceone',
    },
    {
      title: 'Chinese Score',
      dataIndex: 'tourservicetwo',
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
    },  
  ]; 
  
  
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const [productId, setProductId] = useState('')

  const showDrawerFun =(pid:any)=> {
    setProductId(pid)  
    
    setOpen(true);
  }
 
  const tabledata = {
    "buttonbook": {
      "bookbutton":<div className='d-flex justify-content-between'><div className='strtfmrdtl'><b>Start From</b><span className='dtlstrtfrm ps-1'>{packageData?.price } / adult</span></div>
     
      <TdtButton label={'Book Now'} onClick={() => showDrawerFun(packageData?.pid)}/></div>, 
    },
    "iconData": [
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg1 '><TdtIcon icon={<FaCalendar />}/>Tour Service:<span className='ps-1'> Daily</span></div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg11'><TdtIcon icon={<FaHourglass />}/>Duration:<span className='ps-1'>{packageData?.duration} {packageData?.duration_type}</span></div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg2'><TdtIcon icon={<FaCar />}/>Pick up & Drop Back</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg22'><TdtIcon icon={<FaClock />}/>Pick Up:{packageData?.pickup_time}</div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg3'><TdtIcon icon={<FaLanguage />}/>Lang: English / Arabic</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg33'><TdtIcon icon={<FaWifi />}/>Free Wifi Available</div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg4'><TdtIcon icon={<FaMoneyBill />}/>Adult: AED <del className='px-1'>{packageData?.discount_price} </del> {packageData?.price}</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg44'><TdtIcon icon={<FaMoneyBill />}/>Child: AED <del className='px-1'>{packageData?.child_discount_price}</del> {packageData?.child_price}</div>,  
      }
    ]
  }

 
  const listreviewdata=[
    <List.Item.Meta
    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
    title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Sarah M </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
    description="I had an incredible experience with The Deluxe Travel. The tour guides were knowledgeable and friendly, making each stop along the way unforgettable. The accommodations were top-notch, and everything ran smoothly from start to finish. Highly recommend for anyone looking to explore new destinations with ease!"
  />,
  <List.Item.Meta
  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
  title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jake T </span> <TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
  description="The tour with The Deluxe Travel was well-organized and had a good mix of sightseeing and free time. The only downside was the long bus rides between destinations, but the guides kept us entertained with fun facts and stories. Overall, a great value for the price!"
/>, 
    <List.Item.Meta
    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
    title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Emily H </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
    description="Such an amazing adventure! The Deluxe Travel took us to places I never thought I’d see. The itinerary was perfectly balanced – we got to see all the major landmarks, plus some hidden gems off the beaten path. I’ll definitely book with them again for my next trip."
  />, 
  <List.Item.Meta
  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
  title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Raj P </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
  description="Great trip! The tour guides from The Deluxe Travel were super friendly and went out of their way to make sure we were comfortable. I especially loved the local food tours – a real highlight. Only reason I’m giving it 4 stars is because the group size was a bit larger than I expected."
/>, 
    <List.Item.Meta
    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
    title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Ava C </span> <TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
    description="Highly impressed with the level of detail and care The Deluxe Travel puts into its tours. The itinerary was packed but never rushed, and the local experiences felt authentic. I appreciated how organized everything was, from the pick-up at the airport to the drop-off at the hotel."
  />, 
  <List.Item.Meta
  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
  title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jack Man </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
  description="The trip itself was fine, but I felt that the tour was a bit rushed. We didn’t spend as much time as I would’ve liked at some of the locations, and the pacing was a little too fast for my taste. It’s a good option for first-time travelers who want a lot in a short time, but I prefer a more relaxed pace."
/>,   
  ]
 
  const onChangeSort: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

    
const relatedpackages = [
  {
      img: process.env.PUBLIC_URL  + 'assets/tra7.png',
      title: "Places to visit in Dubai 4", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra8.png',
      title: "Places to visit in Dubai 5", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra9.png',
      title: "Places to visit in Dubai 6", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra10.png',
      title: "Places to visit in Dubai 7", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra11.png',
      title: "Places to visit in Dubai 8", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra12.png',
      title: "Places to visit in Dubai 9", 
  },
]; 
const backgroundImageStyle = allCategories && allCategories.length > 0 
  ? { backgroundImage: `url(${projectpath}/public/panelassets/images/uploads/${allCategories[0].image})` } 
  : {};


    return ( 
        <React.Fragment> 

          <TdtBookDrwer destroyOnClose={true} title={'Booking Detail'} pid={productId} open={open} onClose={onClose}/>
         
          <>
            <Container fluid className='bannerBread py-5 position-relative' style={backgroundImageStyle}>

              <Container className='bannerbredcontent py-3'>
                <Row>
                  <Col sm={12} className='py-5'>
                    <h4 className='text-light'><a href='http://thedeluxetravel.com/'  className='text-white'> Home </a> / {packageData?.cat_title}</h4> 
                  </Col>
                </Row>
              </Container>
              <div className='ovelaybred'></div>
            </Container> 

          <Container fluid className='position-relative py-5'> 
            <Container>
              <Row>
                <Col sm={12} md={7} className='listcrdcontent gap-5'>  
                  
                    <SliderDetailTourPage adventureindubai={slideImages} dots={true} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1} className='pt-0'/>  
                     
                    <div className='pt-1'>
                    <h1 className='detailpakglbl'>{packageData?.title}</h1> 
                      <div className='detailpakgrating d-flex pb-2'>   
                            {Array.from({ length: packageData?.rating || 0 }).map((_, index) => (
                              <div key={index}>
                                <TdtIcon icon={<FaStar />}/>
                              </div>
                            ))}  
                          <span className='revstxt'>Reviews</span><span className='px-2'>|</span>
                          <span className='revstxttags'>{packageData?.tags}</span> 
                      </div>
                    </div> 
                    {packageData && ( 
                      <div className='ckeditorpkgdtl'
                        dangerouslySetInnerHTML={{
                          __html: packageData.description,
                        }}
                      />
                     )}  
                    {/* <div className='pt-4'>
                      <h5>Package Inclusions:</h5> 
                      <TdtListStyle listdata={packageData?.AdditionalOptions.title} bordered={true} className='pkgdtlincude' />
                    </div>  */}
                    <div className='pt-4'>
                      <h5 className='pb-3'>Additional Options:</h5> 
                      <TdtTimeline timelineitems={packageData?.AdditionalOptions} />
                    </div> 
                    <div className=''>
                      <h5 className=''>Reviews</h5>  
                      <div className="d-flex justify-content-between pt-3 pb-2">
                          <h5 className="setheadingrow hightlight">Google Reviews</h5>
                          <TdtButton label="Leave a review" href="#" />
                      </div>  
                      <TdtListStyle listdata={listreviewdata} bordered={true} className='pkgdtlrevies' />
                    </div>
                    <div>
                    {packageData && ( 
                      <div className='ckeditorpkgdtl'
                        dangerouslySetInnerHTML={{
                          __html: packageData.long_description,
                        }}
                      />
                     )}  
                    </div>
                    <div className='pt-3'>
                      <h5 className=''>Related packages you like </h5> 
                       <TdtCauroselPackage adventureindubai={relatedPackageData} dots={true} infinite={true} speed={500} slidesToShow={3} slidesToScroll={1} />  
          
                    </div>   
                </Col>
                <Col sm={12} md={5} className='drtsfrsidbar'>
                  <div className='sidebartrours'>
                    <TdtTable showHeader={false} showFooter={tabledata.buttonbook.bookbutton} bordered={true} tblheadcolumns={tblheadcolumns} tabledata={tabledata.iconData} pagination={false} onChangeSort={onChangeSort}/>
                    <ReasonsToWork  className='detaipageresons' /> 
                    <div className='contactsinglecard'>
                      <div className='headercontact'>
                        <TdtImage src={process.env.PUBLIC_URL  + 'assets/deluxetravellogo.svg'} classNameimg='w-75' preview={false} />
                      </div>
                      <div className='contentcontact'>
                        <h6 className='pb-3'>Need Help?</h6> 
                        <ul className='liststyle '><li><a href='https://web.whatsapp.com/send?phone=+971 52 953 5800'><TdtIcon icon={<FaSquarePhone/>} label={'+971 52 953 5800'}/></a></li><li> <a href='https://web.whatsapp.com/send?phone=+971 52 953 5800'><TdtIcon icon={<FaWhatsapp/>} label={'+971 52 953 5800'}/></a> </li><li> <a href='mailto:holidays@thedeluxetravel.com'><TdtIcon icon={<FaEnvelope/>} label={'holidays@thedeluxetravel.com'}/></a> </li></ul>
                        <p className='pt-3'>Available 24/7</p> 
                      </div>  
                      <div className='contactsnglfootr'>
                        <ul className=' d-flex liststyle '><li><TdtIcon icon={<FaLinkedin/>}/></li><li> <TdtIcon icon={<FaFacebook/>}/></li><li> <TdtIcon icon={<FaInstagram/>}/></li><li><TdtIcon icon={<FaYoutube/>}/></li></ul>
                      </div>
                    </div> 
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
          </> 
          
          
        </React.Fragment>
    )
}

export default TourDetail;





