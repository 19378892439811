import Carousel from 'react-bootstrap/Carousel';

interface Carouselprops{
    bannerdescription?:any
} 
const projectpath = process.env.REACT_APP_API_URL;


const TdtBannerCauroselTravel: React.FC<Carouselprops> = (props) => {
  return (
    <Carousel>
        {props.bannerdescription.map((item:any, i:any) => (
            <Carousel.Item key={i}>
                <img
                className="d-block w-100"
                src={projectpath + "/public/panelassets/images/uploads/" + item.image}
                alt={item.heading}
                />
                <Carousel.Caption className='banertxtcontn'>
                <h1 className='pb-3 bnrhead'>{item.heading}</h1>
                <p className='bnrdescr'>{item.description}</p>
                </Carousel.Caption>
            </Carousel.Item>
        ))} 
    </Carousel>
  );
}

export default TdtBannerCauroselTravel;