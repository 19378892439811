import React from 'react';
import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { DatePickerProps } from 'antd';
import { DatePicker, Form } from 'antd';

dayjs.extend(customParseFormat);

interface Grcuploadprops {
    id?: string;
    className?: string;
    name?: string;
    label?: string;
    required?: boolean;
    requiredMessage?: string;
    ruleType?: any;
    defaultValue?: any;
}
const dateFormat = 'YYYY/MM/DD';


const TdtDate: React.FC<Grcuploadprops> = (props) => (
    <Form.Item
        label={props.label}
        name={props.name}
        rules={[{ type: 'object' as const, required: props.required, message: props.requiredMessage }]}
    >
        <DatePicker defaultValue={props.defaultValue} format={dateFormat} className='w-100'/>
    </Form.Item>
);

export default TdtDate;

 