import { Form } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react';  
import { Col, Row } from 'react-bootstrap';  
import { TableColumnsType, message } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton'; 
import { WebApi } from 'shared/WebApi'; 
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string 
} 
interface DataType {
  key: any;
  title: string; 
  dataIndex: string,
}  


const DrwrAddMoreInfo: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  };    
 
 
  const onFinish = async (values: any) => {       
    try {

      values.more_info =  editorData
      const response = await WebApi('post', 'api/add_moreinfo', values);  
      const responseData: any = response; 
      if (responseData.status === 200) {  
        message.success('more info added');
        getMoreInformation(); 
      } else {
        console.error('Error: Request error');  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    }
  };
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };  

  const [getMoreinfor, setGetMoreinfor] = useState<any[]>([]);  
 
 
  const getMoreInformation = async () => {   
    try {
      const response = await WebApi('get', 'api/get_moreinfo');
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data  
        setGetMoreinfor(modifiedData[0].information);
        
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 



  useEffect(() => {
    if (props.open) {
        getMoreInformation();
    }
  }, [props.open]);



  const [editorData, setEditorData] = useState<string>(''); 
  
  return (
        <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'950px'} destroyOnClose={props.destroyOnClose}>
            <Form
                name="homemoreinfoform"
                form={form}
                ref={formRef} 
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
              <Row>
              <Col sm={12}> 
                <TdtCKeditor data={getMoreinfor} onChange={(data:string) => setEditorData(data)} />
              </Col>  
                <Col sm={12} md={6}>
                    <TdtButton label={'Save'} htmlType="submit" classNamebtn='me-2 px-5 mt-4'/>
                </Col>  
              </Row>
            </Form> 
        </TdtDrawer>
  );
}

export default DrwrAddMoreInfo;