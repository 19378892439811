import React, { useState } from 'react';
import { Affix, Layout, theme } from 'antd'; 
import HeaderNavbar from '../components/websitelayout/HeaderNavbar';
import FooterArea from '../components/websitelayout/FooterArea';

 
import TopbarHeader from 'components/websitelayout/TopbarHeader'; 


const { Content, Footer } = Layout;

interface HomeLayoutProps {
    children: any;
    className?: string
}

const Weblayout: React.FunctionComponent<HomeLayoutProps> = (props) => {

    const { children } = props;

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

 const [offsetclass, setoffsetclass] = useState("stickyon")
    
    return (
        <Layout>
            <TopbarHeader />
            <Affix offsetTop={1} onChange={(affixed) => setoffsetclass('seteeeee')}>
                <HeaderNavbar className={offsetclass}/>
            </Affix> 

            <Content>
                {children}
            </Content>

            <FooterArea />

        </Layout>
    );

};

export default React.memo(Weblayout);