import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { motion, useScroll, useSpring } from "framer-motion";
import OurServices from './homesections/OurServices';
import BannerSecTwo from './homesections/BannerSecTwo';
import TdtImage from 'components/websitecomponents/TdtImage';
import { Form, FormInstance, message } from 'antd';
import { WebApi } from 'shared/WebApi';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaEnvelope, FaPhone } from 'react-icons/fa6';
import TdtTextarea from 'components/websitecomponents/TdtTextarea';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtCauroselPackage from 'components/websitecomponents/TdtCauroselPackage';
import { useSettings } from 'components/SettingsProvider';



interface ContactUsprops {

}
export const ContactUs: React.FunctionComponent<ContactUsprops> = (props) => {
    const [form] = Form.useForm();
    
    const settings = useSettings(); // Use the settings context  
    const formRef = React.useRef<FormInstance>(null);
   
    const onFinish = async (values: any) => {   
        values.email_primary = settings?.email_primary

        try {
          const response = await WebApi('post', 'api/insert_contactForm', values);  
          const responseData: any = response; 
          if (responseData.status === 200) { 
            message.success('Email sent successfuly'); 
          } else {
            console.error('Error: Request error');  
          }
        } catch (error:any) {
          console.error('Error:', error);  
        }
      };
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
     
    const [relatedPackageData, setRelatedPackageData] = useState<any[]>([]); 
    const getRelatedPackageById = async () => {   
      try {
        const response = await WebApi('get', `api/get_related_packages/${41}`);  
        const responseData: any = response;
        if (responseData.status === 200) { 
          const modifiedData  = responseData.data.data; // Assuming this has the correct structure
          setRelatedPackageData(modifiedData);  
      } 
      } catch (error:any) {
        console.error('Error:', error);  
      } 
    };
    
  useEffect(() => {  
    getRelatedPackageById()  
}, []) 
    return (
        <React.Fragment> 
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact Us are available for you" />
                <link rel="canonical" href="/contact-us" />
                <meta property="og:title" content="A very important title" />
            </Helmet>   

            <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/contactus.jpg)'}}> 
                <Container className='bannerbredcontent'>
                    <Row>
                        <Col sm={12} className='py-5'>
                            <h4 className='text-light'>Contact Us</h4>
                        </Col>
                    </Row>
                </Container>
                <div className='ovelaybred'></div>
            </Container> 

            <Container fluid className=''> 
                <Container className='py-5'>
                    <Row> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='pb-3'>General Inquiries</h1>
                            <p className='px-sm-1 px-md-5 cntctpginq d-flex justify-content-center'><a href="tel:+971 52 9535800"><TdtIcon classNameicon='pe-1' icon={<FaPhone/>}/>{ settings?.phone}</a><a href={`mailto:${settings?.email_primary}`}> <TdtIcon classNameicon='pe-1' icon={<FaEnvelope/>}/> Holidays@thedeluxetravel.com</a></p>
                        </Col>
                        <Col sm={12} md={6} className=''> 
                            <div className='contactus-col1 box-shadow-tdt p-4'> 
                                <h5 className='ps-3 pb-2'>Hours of Operation</h5>
                                <p className='ps-3 pb-2'>24hours a day, 7 days a week.</p>
                                <Form
                                    name="send_contact"
                                    form={form}
                                    ref={formRef} 
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                > 
                                    <Row>
                                        <Col sm={12} md={6} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtInput 
                                                    className="inputclas class-fname"
                                                    name="fname"
                                                    type="text"
                                                    required={true}
                                                    requiredMessage="Please input your first name!"
                                                    label='First Name'
                                                    placeholder='Enter First Name'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                               /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtInput 
                                                    className="inputclas class-lname"
                                                    name="lname"
                                                    type="text"
                                                    // required={true}
                                                    // requiredMessage="Please input your last name!"
                                                    label='Last Name'
                                                    placeholder='Enter Last Name'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className='  '> 
                                            <div className='contcdiv2'>
                                                <TdtInput 
                                                    className="inputclas class-email"
                                                    name="number"
                                                    type="text"
                                                    required={true}
                                                    requiredMessage="Please input your number!"
                                                    label='Phone Number'
                                                    placeholder='Enter number'
                                                    allowClear={true}
                                                    prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtInput
                                                    className="inputclas class-subject"
                                                    name="email"
                                                    type="email"
                                                    required={true}
                                                    requiredMessage="Please input your Email!"
                                                    label='From Email'
                                                    placeholder='Enter Email'
                                                    allowClear={true}
                                                    prefix={<TdtIcon icon={<FaEnvelope/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} className='  '> 
                                            <div className='contcdiv1'>
                                                <TdtInput
                                                    className="inputclas class-subject"
                                                    name="subject"
                                                    type="text"
                                                    required={false}
                                                    requiredMessage="Please input your subject!"
                                                    label='Subject'
                                                    placeholder='Enter Subject'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtTextarea
                                                    className="inputclas class-message"
                                                    name="message"
                                                    type="text"
                                                    // required={false}
                                                    // requiredMessage="Please input your Message!"
                                                    label='Message'
                                                    placeholder='Enter Message'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} className=' '> 
                                            <TdtButton  htmlType={"submit"} label='Submit' classNamebtn={'w-100'} />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col sm={12} md={6} className=''> 
                            <div className='contactus-col2  box-shadow-tdt p-4'>
                                <h5 className=' pb-3'>Here is our Address</h5>
 
                                <iframe src={settings?.location} width="100%" height="470"   loading="lazy"  ></iframe>
                            </div>
                        </Col>
                        <Col sm={12}  className=''> 
                            <div className='pt-5'>
                                <h5 className=''>Our Best Selling Tours</h5> 
                                <TdtCauroselPackage adventureindubai={relatedPackageData} dots={true} infinite={true} speed={500} slidesToShow={4} slidesToScroll={1} />  
                                </div>
                        </Col>
                    </Row>   
                </Container > 
            </Container> 

        </React.Fragment> 


    )
}

export default ContactUs;




