import axios, { AxiosResponse } from 'axios';


const API_BASE_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_BASE_URL,
  // withCredentials: true, 
  // xsrfCookieName: "XSRF-TOKEN", 
  // xsrfHeaderName: "X-XSRF-TOKEN",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  },

});

const logout = () => {
  localStorage.setItem('loginStatus', "logout");
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('username');
  localStorage.removeItem('email');
  localStorage.removeItem('auth');
  setTimeout(() => {
      window.location.href = '/login';
  }, 500);
}

export const WebApi = async (method: 'post' | 'get' | 'put' | 'delete', url: any, values: any = null,  header = "") => {
  try {

    // instance.defaults.headers.common['Authorization'] = `Bearerwww weewe`; 
    const token: any = localStorage.getItem('token')
    instance.defaults.headers['X-Csrf-Token'] = token; 
    instance.defaults.withCredentials = false; // try to set it true/false
    
    // instance.defaults.withXSRFToken = true;

    if(header === "multipart"){
      instance.defaults.headers['Content-Type'] = "multipart/form-data"  
      // const response: AxiosResponse = await instance.postForm(url, data);
      // return response;
    } 

    if(header === "encoderequest"){
      instance.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded" 
      // const response: AxiosResponse = await instance.post(url, data);
      // return response;
    }
    
     let response: AxiosResponse;
     switch (method) {
       case 'get':
         response = await instance.get(url);
         break;
       case 'post':
         response = await instance.post(url, values, token);
         break;
       case 'put':
         response = await instance.put(url, values, token);
         break;
       case 'delete':
         response = await instance.delete(url, token);
         break;
       default:
         throw new Error('Invalid Request method');
     }
      
    // const response: AxiosResponse = await instance[method](url, data); 
     return response;

  } catch (error:any) {
    if (error.response && error.response.status === 401) { 
      logout();
    }
  }
};
