import { Avatar, Badge, Button, Form, Input, InputNumber } from 'antd';
import form, { FormInstance } from 'antd/es/form';
import TdtSelect from 'components/websitecomponents/TdtSelect';
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import TdtDate from 'components/websitecomponents/TdtDate';
import { Col, Row } from 'react-bootstrap';
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import TdtNumberAddon from 'components/websitecomponents/TdtNumberAddon';
import TdtTable from 'components/websitecomponents/TdtTable';
import TdtButton from 'components/websitecomponents/TdtButton';
import { WebApi } from 'shared/WebApi';

type TableRowSelection<T> = TableProps<T>['rowSelection'];

interface TdtBookDrwerprops {
  open?: boolean; 
  onClose?: any; 
  title?: string;
  destroyOnClose?: boolean;
  pid?: string;
}

interface DataType {
  key: any;
  tour_option: string;
  price: string;
  qty_adults: string;
  qty_childern: string;
  addon_price: string;
}

interface AdditionalOption {
  title: string;
  price: number;
  persons: number;
}

interface PackageDataType {
  pid: number;
  cat_id: number;
  cat_title: string;
  title: string;
  description: string;
  price: number;
  discount_price: number;
  child_price: number;
  child_discount_price: number;
  duration_type: string;
  duration: string;
  rating: number;
  package_image: string;
  package_image_thumbnail: string;
  transfer_type: string;
  transfer_type_adultprice: number;
  transfer_type_childprice: number;
  tags: string;
  image: string;
  opt_title: string;
  opt_price: number;
  opt_persons: number;
  long_description: string;
  SlideImages: string[];  
  AdditionalOptions: { [key: string]: AdditionalOption }; 
}

const tabledata: DataType[] = [];
for (let i = 0; i < 10; i++) {
  tabledata.push({
    key: i,
    tour_option: `Tour Option ${i}`,
    price: `AED 100 for ${i} Person(s)`,
    qty_adults: `qty_adults. `,
    qty_childern: `qty_childern. `,
    addon_price: `addon_price. `,
  });
}

const TdtBookDrwer: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);
  
  const [bookingDetails, setBookingDetails] = useState({
    adults: 2,
    children: 0,
    addonsAdults: 1,
    addonsChildren: 1,
  });

  const [packageData, setPackageData] = useState<PackageDataType | null>(null);
  const [tourTotal, setTourTotal] = useState(232);
  const [addonTotal, setAddonTotal] = useState(323);
  const [grandTotal, setGrandTotal] = useState(553);

  const [sharingTransport, setSharingTransport] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const options = [
    { value: 'shared', label: 'Shared' },
    { value: 'private', label: 'Private' }
  ];

  const handleBookingDetailsChange = (field: string, value: any) => {
    setBookingDetails(prevState => ({ ...prevState, [field]: value }));
  };

  const getPackageById = async () => {
    try {
      const response = await WebApi('get', `api/get_package_byId/${props.pid}`);
      const responseData: any = response;
      if (responseData.status === 200) {
        const modifiedData = responseData.data.data;
        setPackageData(modifiedData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getPackageById();
  }, [props.pid]);

  useEffect(() => {
    const calculateTotals = () => {
      if (!packageData) return;
      const { price, child_price } = packageData;
      const totalAdults = bookingDetails.adults * price;
      const totalChildren = bookingDetails.children * child_price;
      const totalAddonsAdults = bookingDetails.addonsAdults * 50;  // Assume addon price
      const totalAddonsChildren = bookingDetails.addonsChildren * 30;  // Assume addon price
      const total = totalAdults + totalChildren + totalAddonsAdults + totalAddonsChildren;
      setTourTotal(totalAdults + totalChildren);
      setAddonTotal(totalAddonsAdults + totalAddonsChildren);
      setGrandTotal(total);
    };
    calculateTotals();
  }, [bookingDetails, packageData]);

  const onFinish = (values: any) => {
    values.start_date = moment(values.start_date).format("YYYY-MM-DD");
    console.log('Form Values:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const tblheadcolumns: TableColumnsType<DataType> = [
    { title: 'Title', dataIndex: 'tour_option', key: 'tour_option' },
    { title: 'Price', dataIndex: 'price', key: 'price' },
    {
      title: 'Adults Qty',
      dataIndex: 'qty_adults',  
      render: (_, record) => (
        <InputNumber
          min={0}
          max={22}
          defaultValue={bookingDetails.addonsAdults}
          onChange={(value) => handleBookingDetailsChange('addonsAdults', value)}
          className='w-100'
        />
      )
    },
    {
      title: 'Children Qty',
      dataIndex: 'qty_childern',
      render: () => (
        <InputNumber
          min={0}
          max={22}
          defaultValue={bookingDetails.addonsChildren}
          onChange={(value) => handleBookingDetailsChange('addonsChildren', value)}
          className='w-100'
        />
      ),
    },
    { 
      title: 'Addon Price', 
      dataIndex: 'addon_price', 
      render: () => addonTotal
    }
  ];

  return (
    <TdtDrawer title={props.title} open={props.open} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
      Addons for Adults: {bookingDetails.addonsAdults}
      Addons for Children: {bookingDetails.addonsChildren}

      <Form
        name="add-designation-form"
        form={form}
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col sm={12} md={3}>
            <TdtSelect
              name="tour_transfer"
              required={true}
              requiredMessage="Tour Transfer is Required"
              optionList={options}
              value={sharingTransport}
              onChange={(value: any) => setSharingTransport(value)}
              filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
              showSearch={true}
              label='Select Tour Transfer' 
              allowClear={true}
            />
          </Col>
          <Col sm={12} md={3}>
            <TdtDate label="Select Tour Date" name="start_date" className='w-100' required={true} requiredMessage="Start Date is Required" />
          </Col>
          <Col sm={12} md={3} className='d-flex flex-column'>
            <label className='pb-2'>No of Adults {packageData?.price}</label>
            <InputNumber
              min={2}
              max={22}
              defaultValue={2}
              onChange={(value) => handleBookingDetailsChange('adults', value)}
              className='w-100'
            />
          </Col>
          <Col sm={12} md={3} className='d-flex flex-column'>
            <label className='pb-2'>No of Children (Max 8 Yrs) @ {packageData?.child_price}</label>
            <InputNumber
              min={0}
              max={22}
              defaultValue={0}
              onChange={(value) => handleBookingDetailsChange('children', value)}
              className='w-100'
            />
          </Col>
          <Col sm={12} className='position-relative'>
            <h5>Customize Additional Inclusions</h5>
            <TdtTable pagination={false} tblheadcolumns={tblheadcolumns} tabledata={tabledata} />
            <div className='d-flex justify-content-between tblfooterdetail'>
              <div className='d-flex gap-4'>
                <div className='tourtotal'>Tour Total: <span><b>{tourTotal}</b></span></div>
                <div className='addontotal'>Addon Total: <span><b>{addonTotal}</b></span></div>
              </div>
              <div className='d-flex gap-4'>
                <div className='grandtotal'>Grand Total: <span><b>{grandTotal}</b></span></div>
              </div>
            </div>
          </Col>
        </Row>

        
        <TdtButton href={'check-out'} label={'Add To Cart'}/>
      </Form>
    </TdtDrawer>
  );
};

export default TdtBookDrwer;
