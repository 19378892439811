import React, { createContext, useContext, useEffect, useState } from 'react';
import { WebApi } from 'shared/WebApi';

const SettingsContext = createContext<any>(null);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    
    const [settings, setSettings] = useState<any>(null); 
    const getSettings = async () => {
        try {
            const response = await WebApi('get', 'api/get_setting');
            const responseData: any = response;
            if (responseData.status === 200) {
                setSettings(responseData.data.data); 
            }
        } catch (error: any) {
            console.error('Error fetching settings:', error);
        }
    }; 
    useEffect(() => {
        getSettings();
    }, []);

    return (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    return useContext(SettingsContext);
};
