import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPen, FaPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';
import { WebApi } from 'shared/WebApi';

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
} 
interface DataType {
  key: any;
  banner_heading: string;
  banner_description: string;
  banner_image: string; 
}  
const tabledata: DataType[] = [];
for (let i = 0; i < 5; i++) {
  tabledata.push({
    key: i,
    banner_heading: `Tour Option `,
    banner_description: `AED 100 for ${i} Person(s)`,
    banner_image: `qty_adults. `, 
  });
}  
const DrwrWhoWeAre: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  
  const [shorteditorData, setShortEditorData] = useState<string>('');
  const [getWhoWeAreAll, setGetWhoWeAreAll] = useState<string>('');
   
  const onFinish = async (values: any) => {   
    values.description = getWhoWeAreAll  
    values.image = whoWeRImageUpload 
    try {
        const response = await WebApi('post', 'api/add_whoweare' , values, 'multipart');  
        const responseData: any = response; 
        if (responseData.status === 200) { 
                message.success('who we are added')  
                getWhoweare()
                console.log('Error: Request error'+responseData.status);  
            }
        else {
          console.error('Error: Request error');  
        }
    } catch (error:any) {
        console.error('Error:', error);  
    }
  }; 


  const getWhoweare = async () => {   
    try {
      const response = await WebApi('get', 'api/get_getWhoweare');
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data  
        setGetWhoWeAreAll(modifiedData[0].description);
        
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
    getWhoweare()
  }, []) 

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    
  
  const [whoWeRImageUpload, setWhoWeRImageUpload] = useState<File | null>(null);
    const whoweRImageUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setWhoWeRImageUpload(file);   
  };
  const [longEditorData, setLongEditorData] = useState<string>('');
 
  return (
        <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
          <Form
          name="who_we_are"
          form={form}
          ref={formRef} 
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          >  
            <Row>
              <Col sm={12} md={8}>
                <label className='pb-3'>Add who we are</label>
                <TdtCKeditor
                  label="Long Description"
                  name="long_description"
                  required={true}
                  data={getWhoWeAreAll}
                  onChange={(data: string) => setGetWhoWeAreAll(data)}
                />
              </Col>  
              <Col sm={12}  md={4} className='d-flex flex-column'> 
                <label className='customlabel'>Who we are image</label>
                <input type="file" required onChange={whoweRImageUploadFun} />
              </Col> 
              
              <Col sm={12} md={12}>
                  <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
              </Col>  
              </Row> 
            </Form>
        </TdtDrawer>
  );
}

export default DrwrWhoWeAre;