import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPen, FaPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';
import { WebApi } from 'shared/WebApi';
import TdtTextarea from 'components/websitecomponents/TdtTextarea';

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
} 
interface DataType {
  key: any;
  banner_heading: string;
  banner_description: string;
  banner_image: string; 
}  
const tabledata: DataType[] = [];
for (let i = 0; i < 5; i++) {
  tabledata.push({
    key: i,
    banner_heading: `Tour Option `,
    banner_description: `AED 100 for ${i} Person(s)`,
    banner_image: `qty_adults. `, 
  });
}  
const AboutDeluxeDrwr: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  
  const [shorteditorData, setShortEditorData] = useState<string>('');
  const [getWhoWeAreAll, setGetWhoWeAreAll] = useState<string>('');
   
  const onFinish = async (values: any) => {     
    values.aboutOurValuesImg = aboutOurValues
    values.aboutMissionStatementImg = aboutMissionStatement
    values.aboutVisionStatementImg = aboutVisionStatement
    try {
        const response = await WebApi('post', 'api/add_AboutDeluxe' , values, 'multipart');  
        const responseData: any = response; 
        if (responseData.status === 200) { 
                message.success('about Deluxe added')  
                getAboutDeluxe()
                console.log('Error: Request error'+responseData.status);  
            }
        else {
          console.error('Error: Request error');  
        }
    } catch (error:any) {
        console.error('Error:', error);  
    }
  }; 


  const getAboutDeluxe = async () => {   
    try {
      const response = await WebApi('get', 'api/get_AboutDeluxe');
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data  
        setGetWhoWeAreAll(modifiedData[0].description); 
        // Setting form values after data is fetched
        form.setFieldsValue({
          about_deluxe_head: modifiedData[0].about_deluxe_head,
          about_deluxe_des: modifiedData[0].about_deluxe_des,
          about_vision_statement: modifiedData[0].about_vision_statement,
          about_vision_statement_des: modifiedData[0].about_vision_statement_des,
          about_mission_statement: modifiedData[0].about_mission_statement,
          about_mission_statement_des: modifiedData[0].about_mission_statement_des,
          about_our_values: modifiedData[0].about_our_values,
          about_our_values_des: modifiedData[0].about_our_values_des
        }); 
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
    getAboutDeluxe()
  }, []) 

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    
  
  const [aboutOurValues, setAboutOurValues] = useState<File | null>(null);
  const aboutOurValuesFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setAboutOurValues(file);   
  }; 

  const [aboutMissionStatement, setAboutMissionStatement] = useState<File | null>(null);
  const aboutMissionStatementFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setAboutMissionStatement(file);   
  }; 

  const [aboutVisionStatement, setAboutVisionStatement] = useState<File | null>(null);
  const aboutVisionStatementFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setAboutVisionStatement(file);   
  };

  return (
    <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
      <Form
        name="aboutedeluxw"
        form={form}
        ref={formRef} 
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >  
        <Row>
          <Col sm={12} md={6}>
            <TdtInput name='about_deluxe_head' label='About Deluxe Heading'/>
          </Col> 
          <Col sm={12} md={6}>
            <TdtTextarea name='about_deluxe_des' label='About Deluxe Description'/>
          </Col> 
          <Col sm={12} > 
            <Row className='p-0'>
              <Col sm={12} md={4} className='border p-3'>
                <input type="file" required onChange={aboutVisionStatementFun} /> 
                <TdtInput name='about_vision_statement' label='Label 1'/>
                <TdtTextarea name='about_vision_statement_des' label='Deluxe Label 1 Description'/>
              </Col>
              <Col sm={12} md={4} className='border p-3'>
                <input type="file" required onChange={aboutMissionStatementFun} /> 
                <TdtInput name='about_mission_statement' label='Label 2'/>
                <TdtTextarea name='about_mission_statement_des' label='Deluxe Label 2 Description'/>
              </Col>
              <Col sm={12} md={4} className='border p-3'>
                <input type="file" required onChange={aboutOurValuesFun} /> 
                <TdtInput name='about_our_values' label='Label 3'/>
                <TdtTextarea name='about_our_values_des' label='Deluxe Label 3 Description'/>
              </Col>
            </Row>
          </Col> 
          <Col sm={12} md={12}>
            <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
          </Col>  
        </Row> 
      </Form>
    </TdtDrawer>
  );
}

export default AboutDeluxeDrwr;
