import { Avatar, Badge } from 'antd';
import React from 'react'; 

interface Reasonstworkprops{
    icon?:any 
    count?:number
}

const TdtCart: React.FC<Reasonstworkprops> = (props) => {
  return (
    <React.Fragment> 
         {/* <a href="#"> */}
            <Badge count={props.count}>
                <Avatar icon={props.icon}  />
            </Badge>
        {/* </a>         */}
    </React.Fragment>
  );
}

export default TdtCart;