import { Avatar, Badge, Button } from 'antd';
import React from 'react'; 
import { NavLink } from 'react-router-dom';

interface Tdtbuttonprops {
    icon?:any
    label?:any
    href?:string  
    classNamebtn?:string
    onClick?:any
    htmlType?:any 
    disable?:any
}

const TdtButton: React.FC<Tdtbuttonprops> = (props) => {
  return (
    <>
    {
      props.href? <Button disabled={props.disable} type="primary" icon={props.icon} href={props.href} className={props.classNamebtn} onClick={props.onClick}>
            {props.label}
        </Button>  :
        <Button disabled={props.disable}  type="primary" htmlType={props.htmlType} icon={props.icon}  className={props.classNamebtn} onClick={props.onClick}>
          {props.label}
        </Button>  
      
    } 
    </> 
  );
}

export default TdtButton;