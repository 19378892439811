import { Form } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react';  
import { Col, Row } from 'react-bootstrap';  
import {  message } from 'antd';   
import TdtButton from 'components/websitecomponents/TdtButton';  
import { WebApi } from 'shared/WebApi'; 
import TdtSelect from 'components/websitecomponents/TdtSelect'; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
  assignPackageId?:any
  assignPackageTitle?:any
}  
const AssignCategory: React.FC<TdtBookDrwerprops> = (props) => {
 
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null); 
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<any[]>([]);  
   
  const onFinish = async (values: any) => {  
    const formData = new FormData(); 
    values.category_id = selectedCategory.id 
    values.package_id = props.assignPackageId  
    try { 
        const response = await WebApi('post', 'api/assign_pkg_cat' , values, 'multipart');  
        const responseData: any = response; 
        if (responseData.status === 200) { 
                message.success('package Assigned') 
                 
                console.log('Error: Request error'+responseData.status);  
            }
        else {
          console.error('Error: Request error');  
        }
    } catch (error:any) {
        console.error('Error:', error);  
    }
  }; 
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };  
 

  const getCategories = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_categories');
      const responseData: any = response;
      if (responseData.status === 200) {
        
        const modifiedData = responseData.data.data.map((item: any) => ({
          label:  item.title,
          value:  item.cat_id,
          image:  item.image,
        }));

        setAllCategories(modifiedData);
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
      getCategories()
  }, []) 

  const onChangeCategory = (value: string[]) => {
    setSelectedCategoryId(value);
    
  };  

  const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string; image: string }>({
    id: '',
    name: '',
    image:''
  });
  
  const onChangeCategoryN = (value: string, option: any) => {
    if (option && option.label && option.image) {
      setSelectedCategory({
        id: value,
        name: option.label,
        image: option.image,
      });
    } else {
      console.error('Option object does not have the expected properties:', option);
    }
  };
  

  return (
    <TdtDrawer title={props.title} open={props.open} placement={props.placement} onClose={props.onClose} width={'550px'} destroyOnClose={props.destroyOnClose}>      
      <Row>
        <Col sm={12}>
          <Form
          name="add_product"
          form={form}
          ref={formRef} 
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          > 
          
          <Row>
              <Col sm={12}>
                <b>Package Name:</b> {props.assignPackageTitle}
              </Col> 
              <Col sm={12} className='pt-4'>
                  <TdtSelect required={true} requiredMessage='select category first' label='Assign Category' allowClear={true} optionList={allCategories} value={selectedCategory.name} onChange={onChangeCategoryN} />
              </Col> 
              
              <Col sm={12} >
                  <TdtButton   label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
              </Col>  
          </Row>
          </Form>
        </Col>  
      </Row>
    </TdtDrawer>
  );
};

export default AssignCategory;
