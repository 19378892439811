import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { motion, useScroll, useSpring } from "framer-motion";
import OurServices from './homesections/OurServices';
import BannerSecTwo from './homesections/BannerSecTwo';
import TdtImage from 'components/websitecomponents/TdtImage';



interface Aboutusprops {

}
export const AboutUs: React.FunctionComponent<Aboutusprops> = (props) => {
   



    return (
        <React.Fragment> 
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="About Us are available for you" />
                <link rel="canonical" href="/about" />
                <meta property="og:title" content="A very important title" />
            </Helmet>   

            <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/banner-img-1.png)'}}> 
                <Container className='bannerbredcontent'>
                    <Row>
                        <Col sm={12} className='py-5'>
                        <h4 className='text-light'>About Us</h4>
                        </Col>
                    </Row>
                </Container>
                <div className='ovelaybred'></div>
            </Container> 


            <Container fluid className=''> 
                <Container className='py-5'>
                    <Row> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='py-3'>About Deluxe Travels L L C</h1>
                            <p className='px-sm-1 px-md-5 mb-3'>
                                Welcome to Deluxe Travel Packages, your ultimate gateway to the diverse tapestry of the United Arab Emirates. We are a travel company with a difference, as we curate experiences to let you lose yourself to the marvel called the UAE.
                            </p>
                            <p className='px-sm-1 px-md-5  mb-3'>
                                Our experiences range from the city of Dubai with its sparkling skyline to the calm desert sands;  from the ancient glories of the fort and historic sites all with an enviable mix of passion, expertise, and an unrelenting commitment to making and delivering unbeatable memories. We are a team of diverse experienced tour consultants and adventure enthusiasts, bringing in a treasure trove of knowledge along with the shared love for the Emirates. We believe each traveler is different, and so should his journey be.
                            </p>
                            <p className='px-sm-1 px-md-5'>
                                That's precisely why we're breaking the mold with personalized services to include the region's first standalone transfer company, exclusive tours, and a deep dive into the cultural heartbeat of this region.
                            </p>
                        </Col>
                    </Row>
                    <Row className='pb-5'> 
                        <Col sm={12} md={4} className='text-center'>
                            <div className='misionvision'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/visionn.png'} preview={false}/>
                                <h6 className='pt-2'>Vision Statement                                 </h6>
                                <p className='p-2'>Deluxe Travel Packages aspires to be the ultimate lighthouse in hospitality and adventure across the United Arab Emirates, guiding its explorers to the breathtaking landscapes and vivid cultures of the UAE.
                                This is what has molded our vision: reaching out to cultures with an open mind and heart and revealing to them the wonders of this part of the world.</p>
                            </div>
                         </Col> 
                        <Col sm={12} md={4} className='text-center'>
                            <div className='misionvision'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/mission.png'}  preview={false}/>
                                <h6 className='pt-2'>Mission Statement</h6>
                                <p className='p-2'>Flawless, authentic, and memorable is the experience we offer, tailor-made according to guest preferences, which will ensure that each journey is memorable. We are committed to excellence, innovation, and personal service that aims to expose the real UAE through our signature tours, which will build a lifetime of memories.
                                </p>
                            </div>
                        </Col>
                        <Col sm={12} md={4} className='text-center'>
                            <div className='misionvision'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/values.png'} preview={false}/>
                                <h6  className='pt-2'>Our Values</h6>
                                <p className='p-2'>While not explicitly mentioned, a commitment to sustainability could be inferred from the focus on cultural and environmental appreciation, ensuring that the exploration of the UAE is done with respect for the local ecosystem and communities.</p>
                            </div>
                        </Col>
                    </Row>  
                </Container > 
            </Container>
                
            <Container fluid className='whatmakesdifferent'> 
                <Container className='py-5'>
                    
                    <Row className='py-5'> 
                        <Col sm={12} md={6} className=''>
                            <h3 className='py-3'>Why Choose Us? </h3>
                            <p className='mb-3'>In an ocean of travel options, who guides your journey is either a drowning question of just another fleeting trip or a transformational experience. Deluxe Travel Package unfold as a beacon for those who would care to have more than a mere holiday in the United Arab Emirates. 
                                Our commitment to excellence, personalized adventures, and a suite of comprehensive services is what stands to go above and beyond. At the heart of our philosophy is a deep-rooted passion for the UAE, its heritage, and its future.</p>
                            <p>From the first spark of wanderlust to a lasting glow of fond memories, we're with you every step of the way to ensure it's a journey that runs smoothly and true your way.</p>
                            <div className='pt-3'>
                                <h6 className='pt-3 pb-2'>
                                    Unrivaled Expertise:
                                </h6>
                                <p className='m-0'>At the heart of the UAE Team lies unmasked seasoned tour consultants, whose passion and knowledge of the region will make every tour an extraordinary adventure.</p>
                            
                                <h6 className='pt-3 pb-2'>
                                Tailored journey:
                                </h6>
                                <p className='m-0'>Your dream, our blueprint. From the thrill seeker to the culture enthusiast, our custom-designed tour programs work with your interests, budget, and schedule.</p>
                           
                                <h6 className='pt-3 pb-2'>
                                    Exclusive access: 
                                </h6>
                                <p className='m-0'>The best price in the market with Visa facilitation, hotel direct booking, and a premium fleet that offers the most luxurious vehicle brands, available for every kind of tour from desert safari to city explorations.</p>
                           
                                <h6 className='pt-3 pb-2'>
                                Comprehensive Services: 
                                </h6>
                                <p className='m-0'>From the time you land to your last goodbye, services at Gentlemen's are end-to-end, right from airport transfers to ticket bookings and desert safari Dubai experiences that make sure it's a hassle-free adventure. </p>
                           </div>
                        
                        </Col> 
                        <Col sm={12} md={6} className='text-center shadow-lg p-2'>
                            <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutuss.png'} classNameimg='shadow-lg'  preview={false}/>
                        </Col> 
                    </Row> 
                </Container> 
            </Container>
            
            <Container fluid className='pb-5'> 
                <Container className='py-5'>
                    <Row className='aboutuswhtmkdiff'> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='py-3'>What Makes Us Different?</h1>
                            <p className='px-sm-1 px-md-5'>In a world full of travel choices, finding the right partner for your desert safari tour is like turning any vacation into a journey. What sets us apart at Deluxe Travel  Packages is our unique approach to exploring the United Arab Emirates.</p>
                            <p>Our ethos is not just to see new places, but to experience them in a way that does them justice and may be perfectly respectful, enriching, and very deeply personal to you.</p>
                            <p>Herein, we dive down into the core that makes us different, aiming to highlight the very essence of the pillars on which this business is built and the commitment we have to deliver not just a tour but a treasure trove of memories that resonate for you long beyond when the journey has come to an end.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center  px-sm-1 px-md-5 py-3'>
                            {/* <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs.png'} preview={false}/> */}
                            <h5 className='py-2'>Symphony of Experiences</h5>
                            <p>We do not sell tours; we compose the Symphony of Experiences that blends the rich history, vibrant culture, and breathtaking landscapes of the UAE into an odyssey of discovery and wonder.</p>
                        </Col> 
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5 py-3'>
                            {/* <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/> */}
                            <h5 className='py-2'>Personalized Touch</h5>
                            <p>We are believers in personalized services—not just in the customizing of tours, but in every kind of interaction. To us, our guests are not merely tourists but family in Deluxe Travel Packages, and therefore, all have VIP status with tender, loving care.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5 py-3'>
                            {/* <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/> */}
                            <h5 className='py-2'>Sustainable and Responsible Travel</h5>
                            <p>Commons remains steadfast in the belief to preserve the beauty and culture of the UAE. Therefore, our voice for advocacy of responsible practices in tourism and safekeeping of the adventures to be enjoyed sustainably by generations.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5 py-3'>
                            {/* <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/> */}
                            <h5 className='py-2'>Innovation at each step</h5>
                            <p>Be it from the latest travel technology that we offer or even launching some new and unique tour packages—in the list of priorities at every stage, allowing us to improve our services and facilitate experiences for our guests like nowhere else.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5 py-3'>
                            {/* <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/> */}
                            <h5 className='py-2'>Local Heart, Global Mindset</h5>
                            <p>Though deeply entrenched within local traditions and knowledge, our perspective remains global so that the experience is truly local and well within the reach of our international audience.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5 py-3'>
                            {/* <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/> */}
                            <h5 className='py-2'>Expertise and Dedication</h5>
                            <p>With years of experience and a team of passionate experts, we aim to exceed expectations, delivering unforgettable experiences that leave a lasting impression.</p>
                        </Col> 
                   
                    </Row> 
                </Container> 
            </Container>



        </React.Fragment> 


    )
}

export default AboutUs;




