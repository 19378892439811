// AddCategoryDrwr.tsx
import React, { useEffect, useState } from 'react';
import { Button, Form, message} from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import { WebApi } from 'shared/WebApi';
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtImage from 'components/websitecomponents/TdtImage';
import { Col, Row } from 'react-bootstrap';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPencil, FaPlus, FaRegTrashCan } from 'react-icons/fa6';
import PackageFaqs from './PackageFaqs';

const projectpath = process.env.REACT_APP_API_URL;

interface TdtBookDrwerprops {
  open?: boolean;
  onClose?: () => void;
  title?: string;
  destroyOnClose?: boolean;
  placement?: string;
}

const AddCategoryDrwr: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<FormInstance>(null);
  const [uploadfile, setuploadfile] = useState<File | null>(null);
  const [allCategories, setAllCategories] = useState<any[]>([]);
  //  
  const onFinish = async (values: any) => {   
    values.image = uploadfile;    
    try {
      const response = await WebApi('post', 'api/insert_category', values, 'multipart');  
      const responseData: any = response; 
      if (responseData.status === 200) { 
        message.success('Category added');
        getCategories(); 
      } else {
        console.error('Error: Request error');  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    }
  };
  
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };
  
  const getCategories = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_categories');
      const responseData: any = response;
      if (responseData.status === 200) {
        const modifiedData = responseData.data;  
        setAllCategories(modifiedData.data);
        //  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };
  
  const deleteCategory = async (cat_id: any) => {
    try {
      const response = await WebApi('post', 'api/delete_category', { cat_id: cat_id });
      const responseData: any = response; 
      if (responseData.status === 200) { 
        message.success('Category deleted successfully');
        getCategories();  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };

  useEffect(() => {
    if (props.open) {
      getCategories();
    }
  }, [props.open]);

  const handleCompanyLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setuploadfile(file);   
  };

  
  
const [addFaqs, setaddFaqsFun] = useState(false); 
const [addFaqsCatId, setaddFaqsCatId] = useState(''); 
const addFaqsFun = (cat_id:any) => {
    setaddFaqsFun(true);
    setaddFaqsCatId(cat_id);
}

 
const onClose = async (cat_id: any) => {  
  setaddFaqsFun(false); 

}; 



  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title', 
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (_:any, record:any) => (
        <TdtImage src={`${projectpath}public/panelassets/images/uploads/${record.image}`} preview={true} width={'60px'}/>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_:any, record:any) => (
        <div className='d-flex gap-2'> 
        <TdtIcon onClick={()=>deleteCategory(record.cat_id)} icon={<FaRegTrashCan />}  />
        <TdtIcon onClick={()=>addFaqsFun(record.cat_id)} icon={<FaPlus />}  /> 
        </div>
      ),
    },
  ];

  return (
    <TdtDrawer title={props.title} open={props.open} placement={props.placement} onClose={props.onClose} width={'1050px'} destroyOnClose={props.destroyOnClose}>
        {addFaqs && <PackageFaqs open={addFaqs} addFaqsCatId={addFaqsCatId} onClose={onClose} title='Add Package Faqs Page' placement='right'/>} 
      <Form
        name="add_categories"
        form={form}
        ref={formRef} 
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      > 
        <Row>
          <Col sm={12} md={5}>
            <TdtInput label='Category Title' name='title' />
          </Col> 
          <Col sm={12} md={4} className='d-flex flex-column'> 
            <label className='customlabel'>Category Image</label>
            <input type="file" onChange={handleCompanyLogoChange} />
          </Col>  
          <Col sm={12} md={12}>
            <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5'/> 
          </Col>
        </Row>
      </Form> 
      <Row>
        <Col sm={12}>
          <TdtTable pagination={false} tblheadcolumns={columns} tabledata={allCategories} />  
        </Col>  
      </Row>
    </TdtDrawer>
  );
};

export default AddCategoryDrwr;
