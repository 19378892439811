import Form, { FormInstance } from 'antd/es/form';  
import React, { useEffect, useState }  from 'react';  
import { Col, Row } from 'react-bootstrap';  
import {message, TableProps} from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable';  
import TdtImage from 'components/websitecomponents/TdtImage'; 
import { WebApi } from 'shared/WebApi';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaLink, FaPencil, FaRegTrashCan, FaTrashCan } from 'react-icons/fa6';
import EditPackageDrwr from './EditPackageDrwr';
import AssignCategory from './AssignCategory';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtInput from 'components/websitecomponents/TdtInput';
const projectpath = process.env.REACT_APP_API_URL;

  
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
}  
const ManageSetting: React.FC<TdtBookDrwerprops> = (props) => { 

    const formRef = React.useRef<FormInstance>(null);    
    const [form] = Form.useForm() 
    const [disableButton, setDisableButton] = useState(false) 
    const [footerLogoUpload, setFooterLogoUpload] = useState<File | null>(null);
    const footerLogoUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        setFooterLogoUpload(file);   
    };  
    const [headerLogoUpload, setHeaderLogoUpload] = useState<File | null>(null);
    const setHeaderLogoUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        setHeaderLogoUpload(file);   
    };
 
    const onFinish = async (values: any) => {   
    values.header_logo = headerLogoUpload 
    values.footer_logo = footerLogoUpload
    try {
        setDisableButton(true)
        const response = await WebApi('post', 'api/insert_setting' , values, 'multipart');  
        const responseData: any = response; 
        if (responseData.status === 200) { 
                message.success('Seddting Updated ')  
                setDisableButton(false) 
                console.log('Error: Request error'+responseData.status);  
            }
        else {
          console.error('Error: Request error');  
        }
    } catch (error:any) {
        console.error('Error:', error);  
    }
  }; 

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };  
  
  const getSetting = async () => {   
    try {
        const response = await WebApi('get', 'api/get_setting');
        const responseData: any = response;
        if (responseData.status === 200) { 
            const modifiedData = responseData.data.data  

            form.setFieldsValue(modifiedData);

        }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
    getSetting()
  }, []) 



  return ( 
        <Row> 
          <Form
          name="add_setting"
          form={form}
          ref={formRef} 
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          > 
            <Row> 
                <Col sm={12} md={3}>
                    <TdtInput type='number' label='Phone' name='phone' required requiredMessage='package title is required' />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput type='number' label='Whatsapp' name='whatsapp' />
                </Col>  
                <Col sm={12} md={3}>
                    <TdtInput  type='number' label='Landline' name='landline'  required requiredMessage='landline is required'/>
                </Col>   
                <Col sm={12} md={3}>
                    <TdtInput type='email'  label='Email' name='email_primary' />
                </Col>   
                <Col sm={12} md={3}>
                    <TdtInput type='email'  label='Email Contact' name='email_secondary' />
                </Col>    
                <Col sm={12} md={3}>
                    <TdtInput label='Location' required requiredMessage='location is required' name='location' placeholder='Paste Embeded Url' />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput label='Facebook' name='facebook'  />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput label='Instagram' name='instagram' />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput label='Youtube' name='youtube' />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput label='LinkedIn' name='linkedin' />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput label='Twitter' name='twitter' />
                </Col> 
                <Col sm={12} md={3}>
                    <TdtInput label='TikTok' name='tiktok' />
                </Col> 
                <Col sm={12}  md={3} className='d-flex flex-column'> 
                    <label className='customlabel'>Header Logo</label>
                    <input type="file" name='header_logo' required onChange={setHeaderLogoUploadFun} />
                </Col> 
                <Col sm={12}  md={3} className='d-flex flex-column'> 
                    <label className='customlabel'>Footer Logo</label>
                    <input type="file" name='footer_logo' required onChange={footerLogoUploadFun} />
                </Col> 
                <Col sm={12} md={12}>
                    <TdtButton   label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
                </Col>  
            </Row>
          </Form>
        </Row> 
  );
}
 
export default React.memo(ManageSetting);