// Cmslayout.tsx
import React, { useState } from 'react';
import { FileOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';
import { Link } from 'react-router-dom';
import HeaderNavbar from 'components/cmslayout/HeaderNavbar';
import { HiArchive, HiHome, HiInformationCircle, HiOutlineUsers, HiPhotograph } from 'react-icons/hi';
import AddCategoryDrwr from 'cmspages/AddCategoryDrwr';
import AddProductDrwr from 'cmspages/AddProductDrwr';
import { AiOutlineSetting } from 'react-icons/ai';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to="/admin-panel">Home</Link>, 'home', <HiHome />),
  getItem(<Link to="/add-about">About Us</Link>, 'about', <HiArchive />),
  // getItem(<Link to="/about-us-page">About Us Update</Link>, 'About Us', <HiOutlineUsers />),
  // getItem(<Link to="/gallery-us">Gallery Update</Link>, 'Gallery', <HiPhotograph />),
  // getItem(<Link to="/contact-us">Contact Update</Link>, 'Contact', <HiInformationCircle />),
  getItem('Packages', 'packages', <UserOutlined />, [
    getItem('Add Package', '6'),
    getItem(<Link to="/view-product">View Packages</Link>, '3'),
    getItem('Add Category', '5'),
  ]),
  getItem(<Link to="/manage-setting">Settings</Link>, 'settings', <AiOutlineSetting />),
  // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  // getItem('Files', '9', <FileOutlined />),
];

interface HomeLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const Cmslayout: React.FC<HomeLayoutProps> = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [addCategoriesDrwr, setAddCategoriesDrwr] = useState(false);
  const [addProductDrwr, setAddProductDrwr] = useState(false);

  const { token: { colorBgContainer } } = theme.useToken();

  const onMenuClick = (key: string) => {  
    if (key === '5') {
      setAddCategoriesDrwr(true);
    }  
    if (key === '6') {
      setAddProductDrwr(true);
    }
  };

  const onClose = () => { 
    setAddCategoriesDrwr(false);
    setAddProductDrwr(false)
  };

  return (
    <Layout style={{ minHeight: '100vh' }} className='adminpanel-layout'>
      <AddProductDrwr open={addProductDrwr} onClose={onClose} destroyOnClose={true} />
      <AddCategoryDrwr open={addCategoriesDrwr} onClose={onClose} destroyOnClose={true} />
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onClick={(e) => onMenuClick(e.key)} />
      </Sider>
      <Layout>
        {/* <HeaderNavbar className='p-0 adminpanelhdr' /> */}
        <Content> 
          {props.children}
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>
          © 2020 - {new Date().getFullYear()}. All rights reserved by thedeluxetravels.com 
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default Cmslayout;
