import { Avatar, Badge } from 'antd';
import React from 'react'; 
import { NavLink } from 'react-router-dom';

interface Tdticonprops{
    icon?:any
    label?:any
    tolink?:string
    classNamelinkdiv?:string
    classNameicon?:string
    classNamelbl?:string
    onClick?:any

}

const TdtIcon: React.FC<Tdticonprops> = (props) => {
  return (
    <React.Fragment> 
        {
         props.tolink? (<NavLink to={props.tolink} className={`d-flex gap-1 ${props.classNamelinkdiv? props.classNamelinkdiv : ''}`}>
            <span className={`iconset ${props.classNameicon? props.classNameicon  : ''}`}>{props.icon}</span>{props.label  && <span className={`lblset ${props.classNamelbl? props.classNamelbl : ''}`}>{props.label}</span>}
         </NavLink>) 
         :
         (
         <div  className={`d-flex gap-1 ${props.classNamelinkdiv? props.classNamelinkdiv : ''}`} onClick={props.onClick}>
            <span className={`iconset ${props.classNameicon? props.classNameicon : ''}`}>{props.icon}</span>{props.label  && <span className={`lblset ${props.classNamelbl? props.classNamelbl : ''}`}>{props.label}</span>}
         </div>)
        }
    </React.Fragment>
  );
}

export default TdtIcon;