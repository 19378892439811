import React from 'react';
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';

interface TdtTableprops {
    onChangeSort?:any
    tabledata?:any
    tblheadcolumns?:any
    pagination?:any
    bordered?:boolean
    showHeader?:boolean
    showFooter?:any
    rowSelection?:any
    title?:any
}


const TdtTable: React.FC<TdtTableprops> = (props) => {
 
    return (
        <Table showHeader={props.showHeader} rowSelection={props.rowSelection} footer={() => props.showFooter} bordered={props.bordered} columns={props.tblheadcolumns} pagination={props.pagination} dataSource={props.tabledata} onChange={props.onChangeSort} />
    )}

export default TdtTable;