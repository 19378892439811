import React from 'react';
import { Timeline } from 'antd';


interface Tdttimelineprops {
    className?: string,
    timelineitems?: any 
}
   
const TdtTimeline: React.FC<Tdttimelineprops> = (props) => {
  
 
  return ( 
    <>
    
    <Timeline  items={Object.values(props.timelineitems || {}).map(
          (option: any, index: number) => ({
            children: (
              <div key={index}>
               {option.title}
              </div>
            ),
          })
        )}
     />
   
    
{/* {props.timelineitems?.AdditionalOptions && Object.values(props.timelineitems?.AdditionalOptions).map((option: any, index: number) => (
    <div key={index}>
      <h4>{option.title}</h4>
      <p>Price: {option.price}</p>
      <p>Persons: {option.persons}</p>
    </div>
  ))} */}
</>
    );
}

export default TdtTimeline;