import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TdtImage from "./TdtImage"; 
const projectpath = process.env.REACT_APP_API_URL;



interface adventureindubaiprops {
  adventureindubai?: any
  dots?:boolean
  infinite?:boolean
  speed?:number
  slidesToShow?:number
  slidesToScroll?:number
  className?:string

}
 
const SliderDetailTourPage: React.FC<adventureindubaiprops> = (props) => {
  const settings = {
    dots: props.dots,
    infinite: props.infinite,
    speed: props.speed,
    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToScroll
  };
  return (
    <Container  className={`TdtCauroselPackage ${props.className}`}>
      
        <Row className="slider-container">  
            <Col className="p-0">
                <Slider {...settings}> 
                    {props.adventureindubai ?(
                    props.adventureindubai.map((item: any, i: any) => (
                        <div key={i} className="card-setsider px-2">
                        <TdtImage
                            preview={false}
                            src={projectpath + '/public/panelassets/images/uploads/' + item.image}
                        />
                        <h6 className="settitlepkg">{item.title}</h6>
                        </div>
                    ))
                    ) : (
                        <TdtImage
                        preview={false}
                        src={projectpath + '/public/panelassets/images/uploads/5881251727020933.jpg'}
                    /> 
                    )}
                </Slider>
            </Col> 
        </Row>
    
    </Container>
  );
}

export default SliderDetailTourPage;
