import { Collapse, CollapseProps } from 'antd';
import React, { useEffect, useState } from 'react'; 
import { Col, Container, Row } from 'react-bootstrap';
import { WebApi } from 'shared/WebApi';

interface Reasonstworkprops {
  icon?: any;
  count?: number;
}

const TdtFaqs: React.FC<Reasonstworkprops> = (props) => {
  const [faqItems, setFaqItems] = useState<any>(null);  // State to store FAQ data (including faq heading and description)

  const getHomeFaqs = async () => {   
    try {
      const response = await WebApi('get', 'api/get_homefaqs');
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData = responseData.data.data;
        const newFaqItems = {
          faq: {
            faqheading: modifiedData.faq.faqheading,   // Store FAQ heading
            faqdes: modifiedData.faq.faqdes,           // Store FAQ description
          },
          qa: modifiedData.qa.map((faq: any) => ({
            key: faq.id_home_faqs_QA.toString(),
            label: faq.question,  // Use the question as the label
            children: <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>,  // Use the answer as the content
          }))
        };
        setFaqItems(newFaqItems);  // Set the FAQ items in the state
      }
    } catch (error: any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
    getHomeFaqs();  // Fetch the FAQ data on component mount
  }, []); 

  if (!faqItems) {
    return <div>Loading...</div>;  // Display loading state while fetching data
  }

  // Split the faqItems.qa array into two halves
  const halfIndex = Math.ceil(faqItems.qa.length / 2);
  const faqItems1 = faqItems.qa.slice(0, halfIndex);   // First half of the items
  const faqItems2 = faqItems.qa.slice(halfIndex);      // Second half of the items

  return (
    <Container fluid className='Tdtfaqs pb-5'>
      <Container className='pb-5'>
        <Row> 
          <Col sm={12} className='text-center py-5 px-sm-1 px-md-5'> 
            <h1 className='pb-3'>{faqItems.faq.faqheading}</h1>  {/* Render the FAQ heading */}
            <p className='px-sm-1 px-md-5'>
              {faqItems.faq.faqdes}  {/* Render the FAQ description */}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className='faqs-col1'>
            {/* Render the first half of FAQ items dynamically */}
            <Collapse accordion items={faqItems1} defaultActiveKey={faqItems1.length > 0 ? [faqItems1[0].key] : []} />
          </Col>
          <Col sm={12} md={6} className='faqs-col2'>
            {/* Render the second half of FAQ items dynamically */}
            <Collapse accordion items={faqItems2} defaultActiveKey={faqItems2.length > 0 ? [faqItems2[0].key] : []} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default TdtFaqs;
