import TdtButton from 'components/websitecomponents/TdtButton';
import React, {  useState } from 'react';   
import { Col, Container, Row } from 'react-bootstrap'; 
import AboutDeluxeDrwr from './AboutDeluxeDrwr';

interface Dashboardprops { 
}

   

export const AddAboutUs: React.FunctionComponent<Dashboardprops> = (props) => { 
 
    const [aboutDeluxe, setAddAboutDeluxe] = useState(false); 
    const addAboutDeluxeFun = () => {
        setAddAboutDeluxe(true);
    }   
 
    
    
    const onClose = () => { 
        setAddAboutDeluxe(false);
    };

   
    return (  
        <React.Fragment>  
           {aboutDeluxe &&  
            <AboutDeluxeDrwr open={aboutDeluxe} onClose={onClose} title='Aboute Deluxe' placement='right'/>
           }
           
           
           <Container fluid className='dashboardpage'> 
                <Container  className='px-0 py-4'>
                    <Row> 
                        <Col sm={12} md={4} className='pb-3'>
                            <div  className='card p-5 homedashbrcard'>
                            <h5 className='text-center pb-3'>About Deluxe</h5>
                            <div className='cardsbtns'>
                                <div className='d-flex gap-3 justify-content-center '>
                                    <TdtButton label={'Add'} onClick={addAboutDeluxeFun}/> <span className='txtOR'>OR</span> <TdtButton onClick={addAboutDeluxeFun} label={'View'}/>
                                </div> 
                                {/* <TdtButton label={'View'} onClick={addBannerFun} classNamebtn='viewbtndashhomecard'/> */}
                            </div>
                            </div> 
                        </Col>
                    </Row>
                </Container>
            </Container> 
        </React.Fragment>
    )
}

export default AddAboutUs;




